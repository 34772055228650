import { formatDate } from "utils/formatDate";
import { RootReducer } from "../../reducers";
import { DATE_FORMATS } from "../../constants";
import {
  Instance,
  InstancesCached,
  InstancesOverviewData,
  OrphanedInstancesCached,
  RegionCacheData,
  TableOrphanedVirtualMachine,
  TableVirtualMachine
} from "./types";
import { createSelector } from "reselect";

// gpu instances
export const areGpuInstancesLoadingSelector = (state: RootReducer): boolean =>
  state.instances.areGpuInstancesLoading;

export const gpuInstancesSelector = (state: RootReducer): Instance[] | null =>
  state.instances.gpuInstances;

export const tableGpuVirtualMachinesSelector = (
  state: RootReducer
): TableVirtualMachine[] | null =>
  state.instances.gpuInstances?.map((instance) => ({
    id: instance.id,
    name: instance.name,
    status: instance.status,
    ips: instance.ips,
    vCPUs: instance.flavor.vcpus || 0,
    memory: `${(instance.flavor?.ram || 0) / 1024} GiB`,
    flavor: instance.flavor.original_name,
    created: formatDate(new Date(instance.created), DATE_FORMATS.DATETIME),
    project_id: instance.project_id,
    project_name: instance.project_name,
    user_name: instance.user_name,
    user_id: instance.user_id,
    email: instance.email,
    region: instance.region,
    organization_id: instance.organization_id,
    faultMessage: instance.fault?.message || "No details"
  })) || null;

// all instances
export const areInstancesLoadingSelector = (state: RootReducer): boolean =>
  state.instances.areInstancesLoading;

export const instancesSelector = (state: RootReducer): Instance[] | null =>
  state.instances.instances;

export const tableVirtualMachinesSelector = createSelector(
  [instancesSelector],
  (instances) =>
    instances?.map((instance) => ({
      id: instance.id,
      name: instance.name,
      status: instance.status,
      ips: instance.ips,
      vCPUs: instance.flavor.vcpus || 0,
      memory: `${(instance.flavor?.ram || 0) / 1024} GiB`,
      flavor: instance.flavor.original_name,
      created: formatDate(new Date(instance.created), DATE_FORMATS.DATETIME),
      project_id: instance.project_id,
      project_name: instance.project_name,
      user_name: instance.user_name,
      user_id: instance.user_id,
      email: instance.email,
      region: instance.region,
      organization_id: instance.organization_id,
      faultMessage: instance.fault?.message || "No details"
    })) || null
);

// all instances cashed
export const areInstancesCachedLoadingSelector = (
  state: RootReducer
): boolean => state.instances.areInstancesCachedLoading;

export const instancesCachedSelector = (
  state: RootReducer
): InstancesCached | null => state.instances.instancesCached;

export const tableVirtualMachinesCachedSelector = createSelector(
  [instancesCachedSelector],
  (instancesCached): TableVirtualMachine[] | null =>
    instancesCached
      ? Object.values(instancesCached.servers_by_regions).flatMap((region) =>
          region.servers.map((instance) => ({
            id: instance.id,
            name: instance.name,
            status: instance.status,
            ips: instance.ips,
            vCPUs: instance.flavor.vcpus || 0,
            memory: `${(instance.flavor.ram || 0) / 1024} GiB`,
            flavor: instance.flavor.original_name,
            created: formatDate(
              new Date(instance.created),
              DATE_FORMATS.DATETIME
            ),
            project_id: instance.project_id,
            project_name: instance.project_name,
            user_name: instance.user_name,
            user_id: instance.user_id,
            email: instance.email,
            region: instance.region,
            organization_id: instance.organization_id,
            faultMessage: instance.fault?.message || "No details"
          }))
        )
      : null
);

export const instancesOverviewSelector = createSelector(
  [instancesCachedSelector],
  (instancesCached): InstancesOverviewData[] | null =>
    instancesCached
      ? Object.entries(instancesCached.servers_by_regions).map(
          ([region, data]) => ({
            region,
            total_servers_count: data.total_servers_count,
            gpu_servers_count: data.gpu_servers_count,
            other_servers_count: data.other_servers_count,
            statistics_by_statuses: data.statistics_by_statuses,
            statistics_by_flavors: data.statistics_by_flavors
          })
        )
      : null
);

// export const instancesCashDataSelector = createSelector(
//   [(state: RootReducer) => state.instances.instancesCached],
//   (instancesCached): RegionCacheData[] | null =>
//     instancesCached
//       ? Object.entries(instancesCached.servers_by_regions).map(
//           ([region, data]) => ({
//             region,
//             cached_at: formatDate(
//               new Date(data.cached_at),
//               DATE_FORMATS.DATETIME
//             )
//           })
//         )
//       : null
// );

export const instancesCashDateSelector = createSelector(
  [(state: RootReducer) => state.instances.instancesCached],
  (instancesCached): string | null =>
    instancesCached
      ? formatDate(new Date(instancesCached.cached_at), DATE_FORMATS.DATETIME)
      : null
);

export const isInstancesCacheUpdatingSelector = (state: RootReducer): boolean =>
  state.instances.isCacheUpdating;

// orphaned instances cashed
export const areOrphanedInstancesCachedLoadingSelector = (
  state: RootReducer
): boolean => state.instances.areOrphanedInstancesCachedLoading;

export const orphanedInstancesCachedSelector = (
  state: RootReducer
): OrphanedInstancesCached | null => state.instances.orphanedInstancesCached;

export const tableOrphanedVirtualMachinesCachedSelector = createSelector(
  [orphanedInstancesCachedSelector],
  (orphanedInstancesCached): TableOrphanedVirtualMachine[] | null =>
    orphanedInstancesCached
      ? orphanedInstancesCached.abandoned_servers.map((instance) => ({
          id: instance.id,
          name: instance.name,
          status: instance.status,
          ips: instance.ips || "",
          vCPUs: instance.flavor?.vcpus || 0,
          memory: instance.flavor
            ? `${(instance.flavor.ram || 0) / 1024} GiB`
            : "",
          flavor: instance.flavor?.original_name || "Unknown",
          created: instance.created
            ? formatDate(new Date(instance.created), DATE_FORMATS.DATETIME)
            : "",
          project_id: instance.project_id,
          project_name: instance.project_name,
          user_name: instance.user_name,
          user_id: instance.user_id,
          email: instance.email,
          region: instance.region,
          organization_id: instance.organization_id
        }))
      : null
);
