import { useKeycloak } from "@react-keycloak/web";
import { BillingOrganizations } from "components/BillingOrganization";
import { BillingProject } from "components/BillingProject";
import { Group } from "components/Group";
import { Organization } from "components/Organization";
import { Organizations } from "components/Organizations";
import { Project } from "components/Project";
import { Projects } from "components/Projects";
import { User } from "components/User";
import { Users } from "components/Users";
import AdminWelcomeDialog from "components/common/AdminWelcomeDialog";
import { ErrorScreen } from "components/common/ErrorScreen";
import PopupBanner from "components/common/PopupBanner";
import { usePrevious } from "hooks/usePrevious";
import * as authActions from "modules/auth/actions";
import { apiSessionSelector, userSelector } from "modules/auth/selectors";
import * as pollingActions from "modules/polling/actions";
import { FC, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { isNull } from "typeGuards/isNull";
import { ADMIN_REMINDER_INTERVAL_IN_MIN, ROUTES } from "../../constants";
import { Container } from "../Container";
import { Loader } from "../common/Loader";
import { RequireAuth } from "../common/RequireAuth";
// import { Public_IPs } from "components/PublicIPs";
import { MonitoringRam } from "components/MonitoringRam";
import { Billing_Charts } from "components/BillingCharts";
import { VirtualMachines } from "components/VirtualMachines";
import { MonitoringOverview } from "components/MonitoringRegionOverviw";
import { MonitoringCPU } from "components/MonitoringCPU";
import { NetworkUsage } from "components/NetworkUsage";
import { FailedREsources } from "components/FailedResources";

const POLL_ID_PREFIX = "ROUTER";

const POLL_IDS = {
  userDetails: "USER_DETAILS"
};

export const Router: FC = () => {
  const dispatch = useDispatch();
  const { keycloak } = useKeycloak();
  const apiSession = useSelector(apiSessionSelector);
  const user = useSelector(userSelector);
  const previousApiSession = usePrevious(apiSession);

  useEffect(() => {
    if (!keycloak.authenticated) {
      localStorage.setItem("justLoggedIn", "false");
      void keycloak.login();
    } else {
      dispatch(authActions.apiRelogin.started());
      dispatch(
        pollingActions.startPolling({
          id: `${POLL_ID_PREFIX}/${POLL_IDS.userDetails}`,
          action: authActions.getUserDetails.started({})
        })
      );
      localStorage.setItem("justLoggedIn", "true");
    }
  }, [dispatch, keycloak, keycloak.authenticated]);

  useEffect(() => {
    if (isNull(previousApiSession) && apiSession) {
      dispatch(authActions.startRefreshApiSessionPolling());
    }
  }, [previousApiSession, apiSession, dispatch]);

  const [showWelcomeDialog, setShowWelcomeDialog] = useState(false);
  const [isBannerVisible, setIsBannerVisible] = useState(false);

  const justLoggedIn = localStorage.getItem("justLoggedIn");
  const nextShowTimestamp = localStorage.getItem("bannerNextShowTimestamp");
  const currentTime = new Date().getTime();

  // to show welcome dialog
  useEffect(() => {
    if (keycloak?.authenticated && justLoggedIn === "false") {
      setShowWelcomeDialog(true);
    }
  }, [keycloak?.authenticated, justLoggedIn]);

  // to show banner
  useEffect(() => {
    if (nextShowTimestamp) {
      if (currentTime >= parseInt(nextShowTimestamp, 10)) {
        setIsBannerVisible(true);
      }
    }
  }, [currentTime, nextShowTimestamp]);

  const handleWelcomeDialogClose = useCallback(() => {
    setShowWelcomeDialog(false);
    const nextShowTimestamp =
      new Date().getTime() + ADMIN_REMINDER_INTERVAL_IN_MIN * 60000;
    localStorage.setItem(
      "bannerNextShowTimestamp",
      nextShowTimestamp.toString()
    );
  }, []);

  const handleBannerClose = useCallback(() => {
    setIsBannerVisible(false);
    const nextShowTimestamp =
      new Date().getTime() + ADMIN_REMINDER_INTERVAL_IN_MIN * 60000;
    localStorage.setItem(
      "bannerNextShowTimestamp",
      nextShowTimestamp.toString()
    );
  }, []);

  return keycloak.authenticated ? (
    <>
      {showWelcomeDialog ? (
        <AdminWelcomeDialog
          onClose={handleWelcomeDialogClose}
          isOpened={showWelcomeDialog}
        />
      ) : apiSession && user ? (
        <BrowserRouter
          future={{
            v7_relativeSplatPath: true,
            v7_startTransition: true
          }}
        >
          <Container>
            {isBannerVisible && <PopupBanner onClose={handleBannerClose} />}
            <Routes>
              <Route
                path={ROUTES.ROOT}
                element={
                  user.attributes?.["role:admin"] ? (
                    <Navigate to={ROUTES.USERS} />
                  ) : (
                    <ErrorScreen message={"Access denied"} />
                  )
                }
              />
              <Route
                path={ROUTES.USERS}
                element={
                  <RequireAuth>
                    <Users />
                  </RequireAuth>
                }
              />
              <Route
                path={ROUTES.USER}
                element={
                  <RequireAuth>
                    <User />
                  </RequireAuth>
                }
              />
              <Route
                path={ROUTES.BILLING_ORGANIZATION}
                element={
                  <RequireAuth>
                    <BillingOrganizations />
                  </RequireAuth>
                }
              />
              <Route
                path={ROUTES.ORGANIZATIONS}
                element={
                  <RequireAuth>
                    <Organizations />
                  </RequireAuth>
                }
              />
              <Route
                path={ROUTES.ORGANIZATION}
                element={
                  <RequireAuth>
                    <Organization />
                  </RequireAuth>
                }
              />
              <Route
                path={ROUTES.GROUP}
                element={
                  <RequireAuth>
                    <Group />
                  </RequireAuth>
                }
              />
              <Route
                path={ROUTES.PROJECTS}
                element={
                  <RequireAuth>
                    <Projects />
                  </RequireAuth>
                }
              />
              <Route
                path={ROUTES.PROJECT}
                element={
                  <RequireAuth>
                    <Project />
                  </RequireAuth>
                }
              />
              <Route
                path={ROUTES.BILLING_PROJECT}
                element={
                  <RequireAuth>
                    <BillingProject />
                  </RequireAuth>
                }
              />

              <Route
                path={ROUTES.VIRTUAL_MACHINES}
                element={
                  <RequireAuth>
                    <VirtualMachines />
                  </RequireAuth>
                }
              />
              <Route
                path={ROUTES.FAILED_RESOURCES}
                element={
                  <RequireAuth>
                    <FailedREsources />
                  </RequireAuth>
                }
              />
              <Route
                path={ROUTES.BILLING_CHARTS}
                element={
                  <RequireAuth>
                    <Billing_Charts />
                  </RequireAuth>
                }
              />

              <Route
                path={ROUTES.NETWORK_USAGE}
                element={
                  <RequireAuth>
                    <NetworkUsage />
                  </RequireAuth>
                }
              />
              <Route
                path={ROUTES.UTILIZATION}
                element={
                  <RequireAuth>
                    <MonitoringOverview />
                  </RequireAuth>
                }
              />
              <Route
                path={ROUTES.HYPERVISORS_OVERVIEW}
                element={
                  <RequireAuth>
                    <MonitoringOverview />
                  </RequireAuth>
                }
              />
              <Route
                path={ROUTES.RAM}
                element={
                  <RequireAuth>
                    <MonitoringRam />
                  </RequireAuth>
                }
              />
              <Route
                path={ROUTES.CPU}
                element={
                  <RequireAuth>
                    <MonitoringCPU />
                  </RequireAuth>
                }
              />
              {/* <Route
                path={ROUTES.NETWORK_USAGE}
                element={
                  <RequireAuth>
                    <NetworkUsage />
                  </RequireAuth>
                }
              /> */}

              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </Container>
        </BrowserRouter>
      ) : (
        <Loader text={"Initializing..."} />
      )}
    </>
  ) : (
    <Loader text={"Redirecting to login..."} />
  );
};
