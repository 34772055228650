import axios, { AxiosResponse, CancelTokenSource } from "axios";
import * as notificationsActions from "modules/notifications/actions";
import { NOTIFICATION_TYPES } from "modules/notifications/types";
import { isPollStoppedSaga } from "modules/polling/sagas";
import { SagaIterator } from "redux-saga";
import { all, call, put, takeEvery, takeLeading } from "redux-saga/effects";
import { Action } from "typescript-fsa";
import { getAxiosErrorMessage } from "utils/getAxiosErrorMessage";
import { axiosInstance } from "../../axios";
import * as actions from "./actions";
import {
  GetErroredVolumesParams,
  GetErroredVolumesResponse,
  GetOrphanedVolumesParams,
  GetOrphanedVolumesResponse
} from "./types";

export const cancelVolumesTokens: {
  [key: string]: CancelTokenSource | undefined | null;
} = {};

export function* getErroredVolumesSaga(
  action: Action<GetErroredVolumesParams>
): SagaIterator<void> {
  try {
    if (!cancelVolumesTokens["listErroredVolumes"]) {
      cancelVolumesTokens["listErroredVolumes"] = axios.CancelToken.source();
    }

    const { regions } = action.payload;
    const response: AxiosResponse<GetErroredVolumesResponse> = yield call(
      axiosInstance.get,
      `gotham-governor/method/admin/volumes/issues`,
      {
        params: {
          regions: regions ? regions : ""
        },
        cancelToken: cancelVolumesTokens["listErroredVolumes"].token
      }
    );
    const isPollStopped: boolean = yield call(isPollStoppedSaga, action);
    if (!isPollStopped) {
      yield put(
        actions.getErroredVolumes.done({
          params: action.payload,
          result: response.data
        })
      );
    }
  } catch (e) {
    if (!axios.isCancel(e)) {
      yield put(
        actions.getErroredVolumes.failed({ params: action.payload, error: e })
      );
      yield put(
        notificationsActions.showNotification({
          title: "Failed to get failed volumes data",
          text: getAxiosErrorMessage(e),
          type: NOTIFICATION_TYPES.ERROR
        })
      );
    }
  }
}

// without polling and cancel prev request - using areOrphanedVolumesLoading for loader (because without polling)
export function* getOrphanedVolumesSaga(
  action: Action<GetOrphanedVolumesParams>
): SagaIterator<void> {
  try {
    const { regions } = action.payload;
    const response: AxiosResponse<GetOrphanedVolumesResponse> = yield call(
      axiosInstance.get,
      `gotham-governor/method/admin/volumes/abandoned`,
      {
        params: {
          regions: regions ? regions : ""
        }
      }
    );
    yield put(
      actions.getOrphanedVolumes.done({
        params: action.payload,
        result: response.data
      })
    );
  } catch (e) {
    yield put(
      actions.getOrphanedVolumes.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to get orphaned volumes data",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* watcherSaga(): SagaIterator<void> {
  yield all([
    takeEvery(actions.getErroredVolumes.started, getErroredVolumesSaga),
    takeEvery(actions.getOrphanedVolumes.started, getOrphanedVolumesSaga)
  ]);
}
