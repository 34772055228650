import { PaletteMode } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import { Head } from "components/common/Head";
import { Loader } from "components/common/Loader";
import { FC } from "react";
import { Provider } from "react-redux";
import { appConfig } from "../../appConfig";
import { useDarkMode } from "../../hooks/useDarkMode";
import { keycloak } from "../../keycloak";
import { store } from "../../store";
import { getTheme } from "../../theme";
import { Router } from "../Router";
import { GlobalStyles } from "./styles";

export const App: FC = () => {
  const { isDarkMode } = useDarkMode();
  let themeMode: PaletteMode = "light";

  if (
    Object.values(appConfig.theme).every((x) => !x) ||
    appConfig.theme.isDarkModeEnabled
  ) {
    themeMode = isDarkMode ? "dark" : "light";
  }

  return (
    <StyledEngineProvider injectFirst>
      <Head />
      <ThemeProvider theme={getTheme(themeMode, appConfig.theme)}>
        <CssBaseline />
        <GlobalStyles />
        <ReactKeycloakProvider
          authClient={keycloak}
          LoadingComponent={<Loader text={"Initializing..."} />}
        >
          <Provider store={store}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Router />
            </LocalizationProvider>
          </Provider>
        </ReactKeycloakProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};
