import actionCreatorFactory from "typescript-fsa";
import {
  GetErroredVolumesParams,
  GetErroredVolumesResponse,
  GetOrphanedVolumesParams,
  GetOrphanedVolumesResponse
} from "./types";

const actionCreator = actionCreatorFactory("VOLUMES");

export const getErroredVolumes = actionCreator.async<
  GetErroredVolumesParams,
  GetErroredVolumesResponse,
  unknown
>("GET_ERRORED_VOLUMES");

export const getOrphanedVolumes = actionCreator.async<
  GetOrphanedVolumesParams,
  GetOrphanedVolumesResponse,
  unknown
>("GET_ORPHANED_VOLUMES");

export const clearErroredVolumesList = actionCreator<void>(
  "CLEAR_ERRORED_VOLUMES_LIST"
);

export const clearOrphanedVolumesList = actionCreator<void>(
  "CLEAR_ORPHANED_VOLUMES_LIST"
);

export const clear = actionCreator<void>("CLEAR");
