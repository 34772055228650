import Typography, { TypographyProps } from "@mui/material/Typography";
import { TableCellText } from "components/common/Table/styles";
import styled from "styled-components";

export const ContactSupportLinkText = styled(Typography)`
  font-size: 0.625rem;
  font-weight: 500;
  line-height: 1.4em;
`;

export const Title = styled(Typography)<
  TypographyProps<"h2", { component: "h2" }>
>`
  margin-right: ${({ theme }) => theme.spacing(2)};
  margin-bottom: ${({ theme }) => theme.spacing(1)};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const SummaryContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;
`;

export const Description = styled(Typography)`
  margin-right: ${({ theme }) => theme.spacing(0.5)};
  color: ${({ theme }) => theme.palette.text.secondary};
  font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
  font-size: smaller;
`;

export const TabsContainer = styled.div`
  margin-top: ${({ theme }) => theme.spacing(1.5)};
`;

export const CustomDiv = styled.div`
  width: 100%;
  text-align: right;
  /* margin-right: ${({ theme }) => theme.spacing(0.5)}; */
`;

export const ToggleLabel = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.secondary};
  font-weight: 450;
  font-size: smaller;
  white-space: nowrap;
`;

export const TypographyColoredDanger = styled(TableCellText)`
  color: ${({ theme }) => theme.palette.error.dark};
  font-weight: 430;
  font-size: 0.85rem;
`;

export const TypographyColoredPrimary = styled(TableCellText)`
  color: ${({ theme }) => theme.palette.primary.main};
  font-weight: 430;
  font-size: 0.85rem;
`;

export const TypographyColoredSuccess = styled(TableCellText)`
  color: ${({ theme }) => theme.palette.success.dark};
  font-weight: 430;
  font-size: 0.85rem;
`;

export const CustomTableCellText = styled(TableCellText)`
  font-size: 0.85rem;
`;
